import React from "react";

const Privacy = () => {
  return null;
  return (
    <div>
      <h1>图谱林软件业务隐私声明</h1>
      <div>
        <div>
          <p>
            图谱林终端有限公司及其全球关联公司（以下合称为“图谱林”、“我们”或“我们的”）充分尊重您的隐私权。我们特此制定本《图谱林软件业务隐私声明》（以下简称为“本声明”）以便您了解我们如何收集、使用、披露、保护、存储及传输您的个人信息。请您仔细阅读本声明。如您有任何疑问，请
            <a href="https://www.tupulin.com/contact">联系我们</a>。
          </p>
          <p>
            本声明适用于显示或提及本声明的图谱林产品、服务：包括计算机应用程序、移动互联网应用程序（以下称“应用”）、软件、工具包、网站以及服务。
          </p>
        </div>
        <div data-index="0">
          <div>一、图谱林如何收集和使用您的个人信息</div>
          <div>
            <p>（一）图谱林如何收集您的个人信息</p>
            <p>
              个人信息是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。您在使用图谱林产品或服务、与我们互动时，我们可能会收集您的个人信息。根据您使用的产品和服务以及您与我们互动的不同，具体收集的数据类型也将有所不同。您并非必须向图谱林提供个人信息，但在某些情况下，如果您选择不提供，图谱林可能无法为您提供相关产品或服务，也无法回应或解决您所遇到的问题。
            </p>
            <p>
              在为您提供相关产品或服务的过程中，图谱林还可能会收集汇总网站访问量、应用下载量、产品销量等统计数据，以便了解用户如何使用我们的产品和服务。就本声明而言，上述统计数据被视为非个人信息。我们会尽力隔离您的个人信息和非个人信息，并单独使用这两种数据。如果个人信息掺杂了非个人信息，依旧会被视作个人信息处理。
            </p>
            <p>
              图谱林仅会出于本声明及各产品隐私通知所述目的收集和使用您的个人信息。下文举例说明了我们可能收集的个人信息：
            </p>
            <p>1. 您向图谱林提供的信息</p>
            <p>
              您需要注册图谱林帐号才能享受某些功能或者服务。当您注册图谱林帐号，或登录图谱林帐号在线购物、下载软件、购买服务时，我们可能要求您提供相应的个人信息，例如您的姓名、邮件地址、手机号码、订单信息、收货地址、付款方式等。
            </p>
            <p>
              图谱林的某些产品可让您与他人通信并共享信息，当您使用图谱林产品与家人和朋友分享内容时，您可能需要创建公开显示的图谱林帐号个人资料，包括昵称、头像等。图谱林可能会收集您提供的与前述人士有关的信息，如姓名、电子邮件地址以及电话号码等。图谱林将采取合理且必要的措施保障前述通信的安全。
            </p>
            <p>
              为满足部分司法管辖区对帐号实名制、游戏防沉迷系统或互联网支付等的要求，图谱林可能会要求您提供由当地政府发放的身份证明或能表明您身份的相关卡证信息。
            </p>
            <p>2. 图谱林在您使用服务过程中获取的信息</p>
            <p>
              在您使用图谱林产品与服务时，图谱林会收集您的设备信息，以及您和您的设备如何与图谱林产品与服务交互的信息，此类信息包括：
            </p>
            <p>
              （1）设备及应用信息。如设备名称、设备标识符、设备激活时间、硬件型号、操作系统版本、应用程序版本、软件识别码、设备及应用设置（如地区/语言/时区/字号）等。
            </p>
            <p>
              （2）移动网络信息。如公共陆地移动网络提供商
              ID（PLMN）、地理位置（设备所在区域的小区识别码）、Internet
              协议（IP）地址⁠等。
            </p>
            <p>
              （3）日志信息。当您使用图谱林的服务或者查看由图谱林提供的内容时，图谱林会自动收集某些信息并存储在日志中，如服务的访问时间、访问次数、访问
              IP、事件信息（如错误、崩溃、重启、升级）等。
            </p>
            <p>
              （4）位置信息。在访问某些基于位置的服务时（例如执行搜索、使用导航软件或查看某个位置的天气），图谱林会收集、使用并处理您设备的模糊位置或准确位置，这些位置信息可能通过
              GPS、WLAN 或服务提供商的网络 ID
              获取。图谱林会询问您要为哪些应用程序启用基于位置的服务。您可在设备的设置菜单中选择关闭设备上的相应权限，拒绝共享您的位置信⁠息。
            </p>
            <p>3. 第三方来源信息</p>
            <p>
              在当地法律允许的情况下，图谱林还可能会从公开或商业的合法来源获取有关您、您的设备或使用服务情况的其他信息，例如您通过第三方社交网站帐户登录我们的网站时，我们从第三方社交网站获取您的信息；或者，当其他个人使用我们的通信服务时，上传了您的联系方式。
            </p>

            <p>（二）图谱林如何使用您的个人信息</p>
            <p>
              图谱林仅在有合法性基础的情形下才会使用您的个人信息。根据适用的当地法律，图谱林可能会基于您的同意、为履行/订立您与图谱林的合同所必需、为保护您或其他人的合法利益、履行法定义务所必需、企业的合法利益等合法性基础，为实现以下目的，使用您的个人信息：
            </p>
            <p>1. 注册和激活您购买的图谱林产品或服务；</p>
            <p>2. 注册图谱林帐号，以便您享用更丰富多彩的功能；</p>
            <p>
              3.
              交付、激活或验证您所请求的产品与服务，或应您的要求对前述产品与服务进行变更、提供技术支持与售后服务；
            </p>
            <p>4. 向您发送操作系统或应用程序更新和安装的通知；</p>
            <p>5. 为您提供个性化用户体验和个性化内⁠容；</p>
            <p>
              6.
              在您同意或应您主动要求，与您联系、向您发送有关您可能感兴趣的产品与服务的信息、邀请您参与图谱林促销活动和市场调查、或向您发送营销信息；
            </p>
            <p>
              7.
              开展内部审计、数据分析和研究，分析业务运营效率并衡量市场份额，改善图谱林的产品与
              服务；
            </p>
            <p>8. 在您选择向我们发送错误详情的情况下排查错误；</p>
            <p>9. 同步和存储您上传或下载的数据以及执行上传和下载所需的数据；</p>
            <p>10. 改善我们的防损和反欺诈计划；</p>
            <p>
              11.
              遵守适用的本地法律法规要求，例如履行电子商务平台管理义务，或遵守合法的政府
              要求；
            </p>
            <p>12. 其他在产品隐私通知中披露的目的。</p>
          </div>
        </div>
        <div data-index="1">
          <div>二、图谱林如何使用 Cookie 和同类技术</div>
          <div>
            <p>
              图谱林的网站、移动应用软件、在线服务、电子邮件及广告可能会使用
              Cookie、像素标签和网站信标等本地存储技术。图谱林将通过 Cookie
              和同类技术收集的信息视为非个人信息。此外，我们有时会将通过这些技术收集的非个人信息与我们持有的其他个人信息合并。当我们以这种方式合并数据后，出于本声明的目的，我们会将合并后的数据视为个人信息。
            </p>
            <p>（一）Cookie</p>
            <p>
              Cookie
              是一种网络服务器存储在计算机或移动设备上的纯文本文件。Cookie
              的内容只能由创建它的服务器检索或读取。每个 Cookie
              对您的网络浏览器或移动应用程序都是唯一的。Cookie
              通常包含标识符、站点名称以及一些号码和字符。
            </p>
            <p>
              图谱林有时会在计算机或移动设备上存储 Cookie，我们启用 Cookie
              的目的在于改善用户体验，包括：
            </p>
            <p>1. 必要类 Cookie：</p>
            <ul>
              <li>
                <p>
                  登录和验证。当您使用图谱林帐号登录网站时，借助
                  Cookie，您可以直接从网站的一个页面跳转到另一个页面，而不必在每个页面上重新登录；
                </p>
              </li>
              <li>
                <p>
                  存储您的偏好和设置。网站能够借助 Cookie
                  来保存设置，如计算机或移动设备的语言、字体大小、购物车和其他浏览偏好等；
                </p>
              </li>
            </ul>
            <p>
              2. 分析 Cookie：统计分析。借助于
              Cookie，图谱林可以收集您使用我们网站和其他应用程序的信息，如记录用户的单次访问（使用会话
              Cookie）或多次访问（使用永久 Cookie）。
            </p>
            <p>
              3. 广告 Cookie：基于兴趣的广告。图谱林使用 Cookie
              来收集有关您在线活动的信息以发现您的兴趣所在，并向您提供与您相关度最高的广告；
            </p>
            <p>
              您可根据自己的偏好管理或删除 Cookie。有关详情，请参见{" "}
              <a href="https://aboutcookies.org/" target="_blank">
                AboutCookies.org
              </a>
              。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止
              Cookie
              的功能。如需详细了解如何更改浏览器设置，请咨询浏览器的提供商。
            </p>
            <p>
              如果您清除
              Cookie，则需要在每一次访问图谱林的网站时亲自更改用户设置。同时也请注意，图谱林的某些服务可能必须使用
              Cookie，禁用 Cookie 可能会影响您使用这些服务的全部或部分
              <span>功能。</span>
            </p>
            <p>（二）网站信标/像素标签</p>
            <p>
              除 Cookie
              外，我们还会在网站上使用网站信标和像素标签等其他同类技术。网站信标通常是一种嵌入到网站或电子邮件中的电子图像，可用于在您查看网页或电子邮件时识别您设备上的
              Cookie。像素标签使图谱林可以以您可阅读的格式传送电子邮件，并使我们能够获知电子邮件是否被打开。
            </p>
            <p>
              图谱林及我们合作的第三方将该类技术用于多种用途，包括分析服务使用情况（与
              Cookie
              配合使用）以及提供更符合您需求的内容及广告。例如，图谱林向您发送的电子邮件可能含有链接至图谱林网站内容的点击
              URL。如果您点击该链接，图谱林则会跟踪此次点击，帮助我们了解您的产品与服务偏好并改善客户服务。
            </p>
            <p>（三）其他本地存储</p>
            <p>
              我们以及我们合作的第三方可能在某些产品与服务中使用其他本地存储技术，例如本地共享对象（又称
              Flash Cookie）和 HTML5 本地存储。这些技术与上述的 Cookie
              类似，它们同样存储在您的设备中，并且可用于记录有关您的活动和首选项的某些信息。但这些技术与标准
              Cookie
              所使用的设备可能不同，因此您可能无法使用标准浏览器工具和设置来控制它们。有关禁用或删除
              Flash Cookie 中所含信息的说明，请单击
              <a
                href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html"
                target="_blank"
              >
                此处
              </a>
              。
            </p>
            <p>（四）Do Not Track（请勿追踪）</p>
            <p>
              很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not
              Track
              请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。
            </p>
            <p>
              图谱林当前并不支持根据您选择的“请勿跟踪”设置或其他类似特性，改变上述Cookie的处理
              <span>方式。</span>
            </p>
          </div>
        </div>
        <div id="privacy-policy-three" data-index="2">
          <div>三、图谱林如何委托处理、共享、转让、公开披露您的个人信息</div>
          <div>
            <p>（一）委托处理</p>
            <p>
              一些情况下，图谱林会委托其他公司代表图谱林处理您的个人信息。例如代表图谱林处理热线、发送电子邮件、提供技术支持的公司，这些公司只能将您的个人信息用于代表图谱林向您提供服务。
            </p>
            <p>
              图谱林会与受托方签署严格的委托处理协议或个人信息处理条款。受托方有义务按照本声明和我们的指示处理相关的个人信息，并采取相关的保密和安全措施来保障个人信息安全。
            </p>
            <p>（二）共享</p>
            <p>
              共享是指图谱林向其他个人信息处理者提供个人信息，且双方分别在个人信息处理活动中自主决定处理目的、处理方式。通常图谱林不会对外共享您的个人信息，但以下情况除外：
            </p>
            <p>
              1.
              在获取同意情况下的共享：获得您的同意后，图谱林会向您指定的第三方共享您授权范围内的信息；
            </p>
            <p>
              2.
              在法定情形下的共享：图谱林可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息；
            </p>
            <p>
              3.
              共享给图谱林的关联公司：您的信息可能会在图谱林的关联公司内共享。我们仅会出于特定、明确而合法的目的在图谱林的关联公司内共享您的信息，并且只会共享提供服务所必要的信息。例如，在注册图谱林帐号时为了避免重复注册，我们需要对拟注册的帐号进行唯一性校验；
            </p>
            <p>
              4.
              共享给业务合作伙伴：图谱林可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴包括：
            </p>
            <p>
              1)
              第三方卖家和第三方开发者：某些产品或服务由第三方直接向您提供，图谱林须将交易相关信息共享给第三方来实现您向其购买商品或服务的需求。例如，您在应用市场内购买其他开发者的商品时，我们须与开发者共享必要的您的信息，交易才能完成。
            </p>
            <p>
              2)
              商品或技术服务的供应商。图谱林可能会将您的个人信息共享给支持我们功能的第三方，包括为我们供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理服务的第三方等。我们共享这些信息的目的是为实现产品及服务的功能，比如我们必须与物流服务提供商共享您的订单信息以安排送货；或者我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等。
            </p>
            <p>
              图谱林会对共享行为和个人信息接收方进行安全评估，并与接收方签署数据保护协议或严格的保密协议，要求他们按照本声明以及采取相关的保密和安全措施来处理个人信息。
            </p>
            <p>（三）转移</p>
            <p>
              在涉及合并、分立、解散、收购或被宣告破产时，如涉及到个人信息转移，我们会提前向您告知接收方的名称和联系方式，要求接收方继续按照本声明处理您的个人信息。接收方变更原先的处理目的、处理方式的，应当重新取得您的同意。
            </p>
            <p>（四）公开披露</p>
            <p>图谱林仅会在以下情况下，公开披露您的个人信息：</p>
            <p>1. 获得您的同意后；</p>
            <p>
              2.基于法律或合理依据的公开披露：在法律、法律程序、诉讼或公共和政府主管部门有要求的情况下，图谱林可能会公开披露您的信⁠息。
            </p>
          </div>
        </div>
        <div id="privacy-policy-four" data-index="3">
          <div>四、图谱林如何保护您的个人信息</div>
          <div>
            <p>
              图谱林重视个人信息的安全，我们采取业内标准做法来保护您的个人信息，防止其遭到未经授权的访问、披露、使用、修改、损坏或丢失。为此，图谱林特别采取了以下措施：
            </p>
            <p>
              （一）我们采取一切合理可行的措施，确保个人信息收集的最小化，不会收集与达到目的无关的个人信息。我们只会在达成本声明所述目的所需的期限内保留您的个人信息，除非需要延长保留期或法律允许；
            </p>
            <p>
              （二）我们会使用加密技术确保数据传输和存储的机密性。我们会使用受信赖的保护机制防止数据和存储数据的服务器遭到恶意攻⁠击；
            </p>
            <p>
              （三）我们会部署访问控制机制，确保只有授权人员才可访问个人信息；并且依照业务需要和人员层级，控制授权人员的数量并对授权人员做到分层级的权限管理；对个人信息的访问都会被记录日志；
            </p>
            <p>
              总之，图谱林会尽力保护您的个人信息。尽管如此，任何措施都无法做到无懈可击，也没有任何产品与服务、网站、数据传输、计算机系统、网络连接是绝对安全的。
            </p>
            <p>
              若不幸发生个人信息安全事件，图谱林将按照当地法律的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将以邮件、短信、推送通知等方式告知您事件相关情况，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照当地监管部门要求，上报个人信息安全事件的处置情⁠况。
            </p>
          </div>
        </div>
        <div data-index="4">
          <div>五、您如何管理您的个人信息</div>
          <div>
            <p>（一）访问、纠正、删除等个人信息主体权利</p>
            <p>
              立法规定了个人信息主体访问、纠正、删除、限制处理个人信息等个人信息主体权利。根据当地法律的要求，个人信息主体或者其代理人可以向图谱林提出行使上述个人信息主体权利的请求（下文统称“请⁠求”）。
            </p>
            <p>1. 提出请求的方式与渠道</p>
            <p>
              个人信息主体请求必须以书面形式提出。即使请求人未说明请求所依据的法规，请求同样有效。一般来说，口头提出的访问请求是无效的。
            </p>
            <p>
              个人信息主体请求可以通过
              <a href="https://www.tupulin.com/contact" target="_blank">
                联系我们页面
              </a>
              中的联系方式发送。
            </p>
            <p>2. 请求的有效性</p>
            <p>
              大多数的法律要求个人信息主体提出请求的时候遵循特定的要求。本声明要求个人信息主体做到以下几点：
            </p>
            <p>
              （1）通过图谱林专门的个人信息主体请求渠道（即
              <a href="https://www.tupulin.com/contact" target="_blank">
                图谱林联系我们页面
              </a>
              ）提出请求；
            </p>
            <p>
              （2）提供足够的信息给图谱林来验证他们的身份（以确保提出请求的人是个人信息主体本人或者其授权人）；
            </p>
            <p>（3）请求内容具体，具有可执行性。</p>

            <p>3. 请求的处理期限</p>
            <p>
              图谱林会竭尽全力确保在个人信息主体访问请求提出的一个月内做出回复，考虑到要求的复杂性和数量，在必要的时候，这一期限可能适当延长。对于延期提供信息的情况，图谱林将通知个人信息主体相关情形和延迟原因。
            </p>
            <p>4. 请求的结果</p>
            <p>个人信息主体请求提出后，将可能出现以下几种结果：</p>
            <p>（1）请求被驳回</p>
            <p>在某些情况下，个人信息主体的请求将被驳回，包括但不限于：</p>
            <p>a. 当地法律未赋予个人信息主体享有相关权利时；</p>
            <p>b. 提出请求的人的身份无法被验证时；</p>
            <p>
              c.
              个人信息主体提出的要求无法验证、超出范围，尤其是重复提起要求的情形时；
            </p>
            <p>
              d.
              如果涉及到的信息跟图谱林在纠纷中要做出的赔偿或者获得的补偿有关，披露信息很有可能会损害图谱林的利益时；
            </p>
            <p>
              e.
              如果保留信息只是出于统计和研究的目的，而且统计和研究结果的公布不会披露个人身
              <span>份时；</span>
            </p>
            <p>f. 其他法律规定的情形。</p>
            <p>
              如果图谱林驳回了个人信息主体访问请求，将正式向请求人说明原因。
            </p>
            <p>（2）请求成功</p>
            <p>
              当（1）中的情形未发生时，图谱林会满足个人信息主体的请求。为了提升请求成功的可能性，请在提出请求时尽可能提供详细的信息，如请求的类别和具体内容、信息持有人的相关信息（如您所使用的图谱林产品及服务的名称）、信息生成或者处理的时间范围（时间范围越小，成功的可能性越大）等。
            </p>
            <p>5. 特别声明</p>
            <p>
              （1）任何人均无权接触其他人的个人信息，除非是被授权人或者监护人。
            </p>
            <p>
              （2）大多数的法律规定都明确了组织可以不向个人信息主体提供数据的情况。这些情况包括以下几种：提供了数据会破坏打击恐怖主义的力量，个人信息主体已经重复申请了多次，或者获取和提供信息会耗费不相称的资源⁠等。
            </p>
            <p>（3）原则上，图谱林不会提供以下信息：</p>
            <p>
              a.
              关于其他人的信息——个人信息主体访问请求想要获取的数据可能会涉及到个人信息主体之外的其他人。除非所涉及的个人同意，否则图谱林不会提供此类信息。
            </p>
            <p>
              b.
              重复申请——请求人提出的是针对同一个个人信息主体的相同或者类似请求，数据在过去一段时间内没有发生变化，而且图谱林已经提供了数据，这种情况下通常图谱林不会再提供数据的副本。此外，图谱林也没有义务来提供已经公诸于众的信息。
            </p>
            <p>
              c.
              保密条件下给出的观点——如果关于个人信息主体的信息是在保密条件下给出的观点，图谱林没有义务来提供这些信息。
            </p>
            <p>
              d.
              特殊文件——图谱林保留的任何特殊信息是不会应个人信息主体的访问请求予以公开的。一般而言，特殊信息包括任何保密文件（例如客户与其律师之间的直接沟通）以及为取得或给出相应法律意见的信息（不论是诉讼本身或法院诉讼的过程中所涉及到的信息）。
            </p>
            <p>（二）撤回同意</p>
            <p>
              当适用的法律要求的情况下，当图谱林基于您的同意处理您的个人信息时，您还有权随时撤回您的同意。但撤销同意不会影响撤回前我们基于您的同意处理您个人信息的合法性及效力，也不影响我们基于其他适当的正当性基础处理您的个人信息。
            </p>
            <p>如何在具体产品和服务中撤回同意，由图谱林在该产品中详细告⁠知。</p>
            <p>（三）我们如何保护逝者的个人信息</p>
            <p>
              对于中国境内的用户，如果您是已故图谱林用户的近亲属，为了自身的合法、正当利益，可以通过访⁠问：
              <a href="https://www.tupulin.com/contact" target="_blank">
                联系我们页面
              </a>
              与我们取得联系，对逝者相关的个人信息行使法律规定的查阅、复制、更正、删除等权利；逝者生前另有安排的除外。请注意，此处个人信息只包含与逝者图谱林帐号相关联的信息。
            </p>
          </div>
        </div>
        <div data-index="6">
          <div>七、第三方链接及其产品与服务</div>
          <div>
            <p>
              为确保流畅的浏览体验，图谱林的网站、应用软件、产品与服务中可能含有第三方网站、产品与服务的链接。图谱林的产品和服务也可能使用或提供来自第三方的产品或服务。图谱林对此类第三方无控制权。您可选择是否访问第三方提供的链接、内容、产品和服务。
            </p>
            <p>
              图谱林无法控制第三方的隐私或数据保护政策，此类第三方也不受到本声明的约束。在向第三方提交个人信息之前，请阅读并参考这些第三方的隐私或数据保护政策。
            </p>
          </div>
        </div>

        <div data-index="8">
          <div>九、本声明如何更新</div>
          <div>
            <p>
              图谱林保留不时更新或修改本声明的权利。如果我们本声明变更，我们会将本声明最新版本发布在我们的官方网站上（
              <a href="https://www.tupulin.com/privacy/" target="_blank">
                https://www.tupulin.com/privacy/
              </a>
              ）。如果我们对本声明做出了重大变更，我们还可能会通过不同渠道向您发送变更通知，例如，在我们的网站上发布通知或者向您发布单独的通知。
            </p>
          </div>
        </div>

        <div data-index="9">
          <div>十、如何联系图谱林</div>
          <div>
            <p>
              如果您有任何疑问、意见或建议请通过访问
              <a href="https://www.tupulin.com/contact/" target="_blank">
                https://www.tupulin.com/contact/
              </a>
              与我们联系。
            </p>
          </div>
        </div>

        <div>
          <div>
            <p>最近更新日期：2022 年 11 月 17 日</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
